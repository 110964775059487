import { trackLink, trackView } from "~/helpers/trackings";
import { formatValueTrackings } from "./helpers";
import { STORE_CODE } from "./constants";

export const hotTopicsPageView = async () => {
    trackView({
        page_name: `${STORE_CODE}:temi-caldi`,
        page_category_1: STORE_CODE,
        page_category_2: 'temi-caldi',
        page_category_10: 'temi-caldi',
        page_content_status: 'free' 
    });
}

export const hotTopicPageView = async (title) => {
    trackView({
        page_name: `${STORE_CODE}:temi-caldi:${formatValueTrackings(title)}`,
        page_category_1: STORE_CODE,
        page_category_2: 'temi-caldi',
        page_category_3: formatValueTrackings(title),
        page_category_10: 'temi-caldi',
        page_content_status: 'free' 
    });
}

export const hubView = async () => {
    trackView({
        page_name: `${STORE_CODE}:hub`,
        page_category_1: STORE_CODE,
        page_category_2: 'hub',
        page_category_10: 'hub',
        page_content_status: 'free' 
    });
}

export const librerieView = async () => {
    trackView({
        page_name: `${STORE_CODE}:librerie`,
        page_category_1: STORE_CODE,
        page_category_2: 'librerie',
        page_category_10: 'librerie',
        page_content_status: 'free' 
    });
}

export const genericPageView = async (title) => {
    trackView({
        page_name: `${STORE_CODE}:${formatValueTrackings(title)}`,
        page_category_1: STORE_CODE,
        page_category_2: formatValueTrackings(title),
        page_category_10: formatValueTrackings(title),
        page_content_status: 'free' 
    });
}

export const temiCaldiLink = async () => {
    trackLink({
        event_name: `${STORE_CODE}:temi-caldi:click-articolo`,
        event_type: `scopri-di-più`
    }, null, `TCL @CLICK: ${STORE_CODE}:temi-caldi:click-articolo`)
}

export const temiCaldiProductLink = async (name, title) => {
    trackLink({
        event_name: `${STORE_CODE}:${formatValueTrackings(title)}:click-prodotto`,
    }, null, `TCPL @CLICK: ${STORE_CODE}:${formatValueTrackings(title)}:click-prodotto`)
}

export const otherTemiCaldiProductLink = async (title) => {
    trackLink({
        event_name: `${STORE_CODE}:${formatValueTrackings(title)}:click-altro-tema-caldo`,
        event_type: `scopri-di-più`
    }, null, `OTCPL @CLICK: ${STORE_CODE}:${formatValueTrackings(title)}:click-prodotto`)
}

export const otherTemiCaldiNavLink = async (title, val) => {
    trackLink({
        event_name: `${STORE_CODE}:${formatValueTrackings(title)}:navigazione`,
        event_type: `${formatValueTrackings(val)}`
    }, null, `OTCNL @CLICK: ${formatValueTrackings(val)}`)
}

export const hubLink = async (val, callback) => {
    trackLink({
        event_name: `${STORE_CODE}:hub:${formatValueTrackings(val)}`,
        event_type: `${formatValueTrackings(val)}`
    }, callback, `HL @CLICK: ${STORE_CODE}:hub:${formatValueTrackings(val)}`)
}
