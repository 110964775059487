export const formatValueTrackings = (val, noSpecialChar = false) => {
    const trimSpaces = val?.toLowerCase().replace(/\s+/g, "-") ?? '';
    return noSpecialChar ? trimSpaces.replace(/[^a-zA-Z ]/g, "") : trimSpaces;
}

export function getCartItemsNames(products) {
    return products.map(item => formatValueTrackings(item.product.name)).join(':');
}

export const parseTitleHero = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const h2Element = doc.querySelector("h2");
    
    if (!h2Element) return "";

    const strongElement = h2Element.querySelector("strong");

    if (strongElement) {
        strongElement.remove();
        return formatValueTrackings(strongElement.textContent.trim());
    }
     
    else {
        return formatValueTrackings(h2Element.textContent.trim());
    }

}

export const findFilterLabels = (filters, selectedFilters) => {
    return Object.entries(selectedFilters).reduce((acc, [key, values]) => {
        const filter = filters.find(f => f.attribute_code === key);
        if (filter) {
            (values as any).forEach(value => {
                const option = filter.options.find(opt => opt.value === value);
                if (option) {
                    acc.push(option.label);
                }
            });
        }
        return acc;
    }, []).join(":");
};

export const parseCartForTrack = (cart, item) => {
    if (cart.items.length > 0) {
        const cartItem = cart.items.find(el => el.product.sku == item.sku);
        return {
            sku: cartItem.product.sku,
            name: cartItem.product.name,
            price: cartItem.prices.row_total_including_tax.value,
            quantity: cartItem.quantity
        }
    }
}

export const getDiscountPrice = (discounts, totals) => {
    if (discounts.length > 0) {
        return discounts.reduce((acc, discount) => acc + discount.value, 0).toFixed(2);
    } else if (totals.total < totals.subtotal) {
        return (totals.total - totals.subtotal).toFixed(2);
    } else {
        return 0;
    }
}
