








































import { defineComponent } from '@nuxtjs/composition-api'
import { PropType } from 'vue';

export default defineComponent({
    props: {
        filters: {
            type: Object /*as PropType<{ [key: string]: number }>*/
        },
        totals: {
            type: Number,
            required: true
        },
        appliedFilters:{
            type: Array as PropType<String[]>,
            required: false
        },
        toggleFilter:{
            type: Function,
            default: ()=>{}
        }
    },
    data() {
        return {
            sprite: require('~/assets/solepro/spritemap.svg')
        }
    },
})
