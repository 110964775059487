import { AxiosRequestConfig } from 'axios';
import { SOLEPRO_STORE_CODE } from '../constants/store';

export const appendStylesFromText = (cssText, media = 'screen') => {
  if (process.client && document) {
    const el = document.createElement('style');
    // WebKit hack
    el.append(document.createTextNode(''));
    el.type = 'text/css';
    el.media = media;
    el.innerHTML = cssText;
    document.head.append(el);
  }
};

/** This function removes all empty entries (null or empty string)  */
// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
export const removeEmptyValuesFromObject = (obj) => Object.fromEntries(Object.keys(obj)
  .filter((k) => obj[k] != null && obj[k] != '')
  .map((k) => [k, obj[k]]));

const valueToHex = (c) => {
  const hex = Number.parseInt(c as string).toString(16);
  return hex.length == 1 ? `0${hex}` : hex;
};

export const rgbaToHex = (rgba) => {
  const [r, g, b, a] = rgba.replace(/rgba?\(|\)|\s/g, '').split(',');
  return {
    color: `#${valueToHex(r)}${valueToHex(g)}${valueToHex(b)}`,
    opacityPercentage: Number.parseInt(Math.round(a * 100).toString()),
  };
};

const calculateTintAndShade = (
  hexColor, // using #663399 as an example
  percentage = 0.1 // using 10% as an example
) => {
  const r = parseInt(hexColor.slice(1, 3), 16); // r = 102
  const g = parseInt(hexColor.slice(3, 5), 16); // g = 51
  const b = parseInt(hexColor.slice(5, 7), 16); // b = 153

  /* 
     From this part, we are using our two formulas
     in this case, here is the formula for tint,
     please be aware that we are performing two validations
     we are using Math.min to set the max level of tint to 255,
     so we don't get values like 280 ;)
     also, we have the Math.round so we don't have values like 243.2
     both validations apply for both tint and shade as you can see */
  const tintR = Math.round(Math.min(255, r + (255 - r) * percentage)); // 117
  const tintG = Math.round(Math.min(255, g + (255 - g) * percentage)); // 71
  const tintB = Math.round(Math.min(255, b + (255 - b) * percentage)); // 163

 
  const shadeR = Math.round(Math.max(0, r - r * percentage)); // 92
  const shadeG = Math.round(Math.max(0, g - g * percentage)); // 46
  const shadeB = Math.round(Math.max(0, b - b * percentage)); // 138


  /* 
     Now with all the values calculated, the only missing stuff is 
     getting our color back to hexadecimal, to achieve that, we are going
     to perform a toString(16) on each value, so we get the hex value
     for each color, and then we just append each value together and voilà!*/
  return {
      tint: {
          r: tintR,
          g: tintG,
          b: tintB,
          hex:
              '#' +
              [tintR, tintG, tintB]
                  .map(x => x.toString(16).padStart(2, '0'))
                  .join(''), // #7547a3 
      },
      shade: {
          r: shadeR,
          g: shadeG,
          b: shadeB,
          hex:
              '#' +
              [shadeR, shadeG, shadeB]
                  .map(x => x.toString(16).padStart(2, '0'))
                  .join(''), // #5c2e8a 
      },
  };
};

export const DarkenColor = (hexColor, percentage) => {
  return calculateTintAndShade(hexColor, percentage).shade.hex
}

/**
 * In the css_classes magento field the column char is not allowed
 * We can use triple underscore to manage this behavior.
 * In order to manage tailwind media classes like md:bg-red lg:bg-white
 * we can use md___bg-red lg___bg-white and the replace ___ with : to use them in html
 */
export const parseClasses = (text: string) => {
  // @ts-ignore
  return text.replaceAll('_open_square_bracket_', '[')
  .replaceAll('_close_square_bracket_', ']')
  .replaceAll('_open_round_bracket_', '(')
  .replaceAll('_close_round_bracket_', ')')
  .replaceAll('!solepro_hidden', '!hidden')
  .replaceAll('solepro_hidden', 'hidden')
  .replaceAll('_slash_', '/')
  .replaceAll('_important_', '!')
  .replaceAll('_percentage_', '%')
  .replaceAll('_AND_', '&')
  .replaceAll('_STAR_', '*')
    .replaceAll('_IN_', '>')
    .replaceAll('___', ':')
    .replaceAll('_DOT_', '.')
}

export const getAxiosConfigs = (app, config) => {
  
  let axiosConfigs: AxiosRequestConfig = {
    headers: {
      Store: SOLEPRO_STORE_CODE
    }
  }
  
  if (app.$config.soleEnv == 'local') {
    axiosConfigs.baseURL = `http://${app.$config.localURL}`
  } else {
    axiosConfigs.baseURL = `${app.$config.soleproDomain}`
  }

  return {
    ...axiosConfigs
  }

} 