
















import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
    props: {
        libreria: {
            type: Object,
            required: true
        }
    }
})
