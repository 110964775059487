





























import { useContext, useFetch, useRouter } from '@nuxtjs/composition-api';
import { defineComponent, ref, watch } from '@vue/composition-api'
import axios from 'axios';
import FilterBox from '~/components/solepro/Librerie/FilterBox.vue';
import Libreria from '~/components/solepro/Librerie/Libreria.vue';
import { useConfig } from '~/composables';
import { SOLEPRO_STORE_CODE } from '~/utils/constants/store';
import { getLayout } from '~/utils/helpers/page';
import { librerieView } from '~/helpers/trackings/24orepro/pages';
import { getAxiosConfigs } from '~/utils/solepro/functions';

export default defineComponent({
    layout(context) {
        return getLayout(context.i18n.locale);
    },
    metaInfo: {
        title: 'Librerie'
    },
    components: {
        FilterBox,
        Libreria
    },
    setup() {
        const { config } = useConfig();
        const { app } = useContext();
        const router = useRouter();
        const list = ref([])
        const fullList = ref([]) // used to manage backup when removing filters
        const filters = ref<{ [key: string]: number }>()
        const librerie = ref([])
        const currentPage = ref(1)
        const pageSize = 20;
        const pages = ref(1)
        const appliedFilters = ref<string[]>([])
        if (config.value.store_code != SOLEPRO_STORE_CODE) {
            router.replace(`/${config.value.store_code}/404.html`)
            return { canView: false, appliedFilters }
        } else {

            useFetch(async () => {
                const { data } = await axios.get('/sole-api/layout/pro/getLibrerie',{...getAxiosConfigs(app, config)});
                list.value = data.librerie;
                fullList.value = data.librerie;
                filters.value = data.filtri;
                pages.value = Math.ceil(data.librerie.length/pageSize)
                librerie.value = list.value.slice(0,  pageSize);

                await librerieView();
            })
            watch(currentPage, () => {
                const start = (currentPage.value - 1) * 20;
                librerie.value = list.value.slice(start, start + pageSize);
            })

            return {
                canView: true,
                list,
                fullList,
                pageSize,
                currentPage,
                librerie,
                filters,
                pages,
                appliedFilters
            }

        }
    },

    methods: {
        prevPage() {
            (this.currentPage as number)++;
        },
        nextPage() {
            (this.currentPage as number)--;
        },
        getPage(page) {
            this.currentPage = page;
            window.scrollTo(0,0)
        },
        toggleFilter(provincia){
            if(this.appliedFilters.includes(provincia)){
                const index = this.appliedFilters.indexOf(provincia);
                this.appliedFilters.splice(index,1)
            }else{
                this.appliedFilters.push(provincia)
            };
            this.appliedFilters.length>0 ? this.applyFilters() : this.removeFilters()
        },
        applyFilters(){
            this.list = (this.fullList as {[key:string]:string}[]).filter(el=>this.appliedFilters.includes(el.provincia) );
            this.librerie = (this.list as Object[]).slice(0, this.pageSize as number)
            this.currentPage=1;
            this.pages = Math.ceil((this.list as {[key:string]:string}[]).length / (this.pageSize as number));
        },
        removeFilters(){
            this.list = this.fullList;
            this.librerie = (this.list as Object[]).slice(0, this.pageSize as number)
            this.currentPage=1;
            this.pages = Math.ceil((this.list as {[key:string]:string}[]).length / (this.pageSize as number));
            this.appliedFilter = null;
        }
    }
})
